// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	// Initialize the Flexslider
	$('.gcom-big-image-slider-wrapper .flexslider').flexslider({
		animation: "fade",
		prevText: "",
		nextText: "",
		controlNav: true,
		directionNav: true,
	});
}); /* end of as page load scripts */